// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


//= require jquery-mobile-rs


require('jquery')
require("jquery-ui")
require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("trix")
require("@rails/actiontext")
require("channels")
require('owl.carousel');
require('imagesloaded');
require('jquery.fancytree');
require('perfect-scrollbar');
require('velocity');
require('scrollmagic');
require('headroom.js');
require("./nested-forms/addFields");
require("./nested-forms/removeFields");
require("d3");
require("d3-hierarchy");
require("sunburst-chart");
require("select2");
require("jsoneditor");
require("highlight.js")
require("diff2html")

// import 'recurring_select'
// import './bootstrap_custom.js'
import 'jquery-ui/ui/widgets/droppable'
import 'jquery-ui/ui/widgets/sortable'
// import 'bootstrap/dist/js/bootstrap';
import '@hotwired/turbo-rails'
Turbo.session.drive = false

// import 'sticky-sidebar/jquery.sticky-sidebar'
// import './gems.js.erb'
import 'social-share-button';
// require('packs/main');
require('packs/script');
require('packs/custom');
// import 'packs/libs-init';





// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


