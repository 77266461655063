// javascript/packs/custom.js
import {createTree} from 'jquery.fancytree';
import Headroom from "headroom.js";
import * as d3 from 'd3';
import Sunburst from 'sunburst-chart'
import Rails from "@rails/ujs";
import select2 from 'select2'
import 'select2/dist/css/select2.css'
import "jsoneditor/dist/jsoneditor.min.css";
import "jsoneditor/dist/jsoneditor.js";
import JSONEditor from 'jsoneditor';
import jQuery from "jquery"
import $, { ajax } from 'jquery';
// import 'jquery.fancytree/dist/skin-lion/ui.fancytree.css';
// import 'jquery.fancytree/dist/modules/jquery.fancytree.js';
import 'jquery.fancytree/dist/modules/jquery.fancytree.dnd5';
import 'jquery.fancytree/dist/modules/jquery.fancytree.columnview.js';
import 'jquery.fancytree/dist/modules/jquery.fancytree.edit';
import 'jquery.fancytree/dist/modules/jquery.fancytree.table';
import 'diff2html/bundles/css/diff2html.min.css'
import { jsondiffpatch, diff, formatters } from 'jsondiffpatch';
import 'jsondiffpatch/dist/formatters-styles/annotated.css';
import 'jsondiffpatch/dist/jsondiffpatch.umd';
import 'jsondiffpatch/dist/formatters-styles/html.css';
import { Notyf } from 'notyf';



// require("select2/dist/css/select2")
// require("select2-bootstrap-theme/dist/select2-bootstrap")


$( document ).on('turbo:load', function() {

  if($('.activity-form').length){
    $('form').on("keydown", ":input:not(textarea):not(:submit)", e => {
      if (e.key == "Enter") {
          return false;
      }
    });
  }

  // const input = document.querySelector("#phone");
  // const iti = window.intlTelInput(input, {
  //   utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.0.4/build/js/utils.js",
  //   separateDialCode: true,
  //   autoPlaceholder: "off",
  //   containerClass: "w-100"
  // });

  // input.addEventListener("countrychange", function() {
  //   // Get the selected country code
  //   var countryCode = iti.getSelectedCountryData().dialCode;

  //   // Set the country code directly to your desired field in the form
  //   document.querySelector("#country_code").value = countryCode;
  // });

  if($("#time_zone_offset_field").length){
    console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
    // var timeZoneOffset = new Date().getTimezoneOffset();
    $('#time_zone_offset_field').val(Intl.DateTimeFormat().resolvedOptions().timeZone)
  }

  // document.addEventListener("DOMContentLoaded", function() {
  //   // Initialize Bootstrap components
  //   // var dropdowns = document.querySelectorAll(".dropdown-toggle");
  //   // Array.prototype.forEach.call(dropdowns, function(dropdown) {
  //   //   var dropdownInstance = new bootstrap.Dropdown(dropdown);

      
  //   // });

  //   var dropdowns = document.querySelectorAll(".dropdown-toggle");

  //   dropdowns.forEach(function (dropdown) {
  //     var dropdownInstance = new bootstrap.Dropdown(dropdown);

  //     // Close the dropdown when a menu item is clicked
  //     var dropdownMenu = dropdownInstance._menu;
  //     dropdownMenu.addEventListener("click", function (event) {
  //       // Prevent the click event from bubbling up and closing the dropdown
  //       event.stopPropagation();
  //       dropdownInstance.hide();
  //     });

  //     // Close the dropdown when clicking outside of it
  //     document.addEventListener("click", function () {
  //       dropdownInstance.hide();
  //     });
  //   });
  // });

  if($('#diffContainer').length){
    document.getElementById('diffContainer').innerHTML = "<p>Loading</p>"
    var left = $('#left').val()
    var right = $('#right').val()
    // var delta = diff(JSON.parse(left), JSON.parse(right));
    var delta = diff(left, right);
    document.getElementById('diffContainer').innerHTML = formatters.html.format(delta, left);
  }

  if ($('[data-section="owl-carousel"]').length) {
    $('[data-section="owl-carousel"]').imagesLoaded( function() {
        $('.owl-carousel').owlCarousel({
            items:1,
            loop:true,
            nav:true,
            navText: [
                "<i class='fa fa-angle-left' aria-hidden='true'></i>",
                "<i class='fa fa-angle-right' aria-hidden='true'></i>"
            ],
            dots: true,
            margin:0,
            autoplay: true,
            autoplayTimeout: 11000,
            autoplayHoverPause: true,
            autoplaySpeed: 1250,
            autoHeight:true
        });
    });
  }


  // Collapse Navbar
  var navbarCollapse = function() {
    if ($("#mainNav").length && $("#mainNav").offset().top > 100) {
      $("#mainNav").addClass("navbar-shrink");
    } else {
      $("#mainNav").removeClass("navbar-shrink");
    }
  };

  // Collapse now if page is not at top
  navbarCollapse();
  // Collapse the navbar when page is scrolled
  $(document).scroll(navbarCollapse);

  // if ($('#header--standard').length) {
  //   // var $header = $('#header--standard');
  //   var $header = document.querySelector('#header--standard');
  //   var options = {
  //     "offset": 100,
  //     "tolerance": 5,
  //     "classes": {
  //       "initial": "animated",
  //       "pinned": "slideDown",
  //       "unpinned": "slideUp"
  //     }
  //   };
  //   var headroom  = new Headroom($header,options);
  //   // initialise
  //   headroom.init();
  // };

  if ($('#book-tree').length) {

    const tree = createTree("#book-tree", {
      // checkbox: false,
      init: function(event, data) {
        var activeJumpNode = data.tree.getActiveNode();
        console.log(activeJumpNode)
        if ( activeJumpNode ) {
            var treeObj = $("div[id^=msctree]").fancytree("getTree");
            var jumpToNode = treeObj.findFirst(function(node) {
                return node === activeJumpNode;
            });
            jumpToNode.setActive();
            jumpToNode.setFocus();
            $(treeObj.$container).focus();
        }           
      },
      selectMode: 1,
      activate: function(event, data) {
        $("#statusLine").text(event.type + ": " + data.node.key);
        const ids = data.node.key.split("--")
        console.log(ids)
        document.location = ids[1];
      },
      select: function(event, data) {
        alert("custom node data: " + JSON.stringify(node.data));
        $("#statusLine").text(
          event.type + ": " + data.node.isSelected().id + " " + data.node.data.id
        );
      },
    });

    // tree.expandAll(false);
    // var activeNode = $("#tree").fancytree("getActiveNode");

    //         // Expand the tree up to the active node
    // if (activeNode) {
    //   expandToActiveNode(activeNode);
    // }
  }

  if ($('#behaviour-tree').length) {

    const tree = createTree("#behaviour-tree", {
      // checkbox: false,
      init: function(event, data) {
        var activeJumpNode = data.tree.getActiveNode();
        if ( activeJumpNode ) {
            var treeObj = $("div[id^=msctree]").fancytree("getTree");
            var jumpToNode = treeObj.findFirst(function(node) {
                return node === activeJumpNode;
            });
            jumpToNode.setActive();
            jumpToNode.setFocus();
            $(treeObj.$container).focus();
        }           
      },
      selectMode: 1,
      extensions: ["dnd5"],
      activate: function(event, data) {
        $("#statusLine").text(event.type + ": " + data.node.key);
        const ids = data.node.key.split("--")
        document.location = "/superuser/accounts/" + ids[0] + "/applications/" + ids[1] + "/behaviours/" + ids[2];
      },
      select: function(event, data) {
        alert("custom node data: " + JSON.stringify(node.data));
        $("#statusLine").text(
          event.type + ": " + data.node.isSelected().id + " " + data.node.data.id
        );
      },
      dnd5: {
        autoExpandMS: 400,
        // focusOnClick: true,
        // preventVoidMoves: true, // Prevent dropping nodes 'before self', etc.
        // preventRecursiveMoves: true, // Prevent dropping nodes on own descendants
        dragStart: function(node, data) {
          /** This function MUST be defined to enable dragging for the tree.
           *  Return false to cancel dragging of node.
           */
          return true;
        },
        dragEnter: function(node, data) {
          // if(node.parent !== data.otherNode.parent) return false;
          // if(node.isExpanded){
          //   if(node.parent !== data.otherNode.parent){
          //     return false;
          // }else{
          //   return true;
          // }
          // }
          return true
          // return ["over", "after"]
        },
        dragDrop: function(node, data) {
          data.otherNode.moveTo(node, data.hitMode);
          var position = data.otherNode.getIndexHier()
          var ids2 = data.otherNode.key.split("--")
          $.ajax({
            url: "/superuser/accounts/" + ids2[0] + "/applications/" + ids2[1] + "/update_sibling_position/",
            beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
            type: "post",
            data: {
              id: ids2[2],   
              other_node_id: node.key.split("--")[2],
              hit_mode: data.hitMode
            },
            success: function(response) {
              // Handle success
            },
            error: function(error) {
              // Handle error
            }
          });
        }
      },
    });

    // tree.expandAll(false);
    // var activeNode = $("#tree").fancytree("getActiveNode");

    //         // Expand the tree up to the active node
    // if (activeNode) {
    //   expandToActiveNode(activeNode);
    // }
  }

  // $('#behaviour-tree').fancytree()

  // if($('#blur-it').length){
  //   var windowCenter =  $(window).width() / 2;
  //   if($('#blur-it').offset().left <= windowCenter){
  //       $("#readMore").modal("show")
  //   }  
  //   else if($('#blur-it').offset().left >= windowCenter){
  //      console.log("element not visible")
  //   } 
  // }

  $('.notification-link').click(function(){
    var profileId = $(this).data('profile')
    $.ajax({
      url: '/' + profileId + '/update_notification_status/' + $(this).data('id'),
      type: "get", 
      success: function(data){
        // // $('#notification-append').append(
        // // '<svg class="olymp-thunder-icon"><use xlink:href="/assets/svg-icons/sprites/icons-e342751a72d4b06b6bb33ef3c513de00dccb0bf1627840f5d342096375593ac7.svg#olymp-thunder-icon"></svg>'
        // // )
        // // $("#notification-hover").remove()
        // updateNotficationIcon()
      }
    })
    // debugger  
  });

  // if($(".json-editor").length){
  //   console.log("hello")
  //   const diffString = `diff --git a/sample.js b/sample.js
  //     index 0000001..0ddf2ba
  //     --- a/sample.js
  //     +++ b/sample.js
  //     @@ -1 +1 @@
  //     -console.log("Hello World!")
  //     +console.log("Hello from Diff2Html!")`;
  //     var targetElement = document.getElementById('diffContainer');
  //     console.log(targetElement)
  //     var configuration = {
  //       drawFileList: true,
  //       fileListToggle: false,
  //       fileListStartVisible: false,
  //       fileContentToggle: false,
  //       matching: 'lines',
  //       outputFormat: 'side-by-side',
  //       synchronisedScroll: true,
  //       highlight: true,
  //       renderNothingWhenEmpty: false,
  //     };
  //     var diff2htmlUi = new Diff2HtmlUI(targetElement, diffString, configuration);
  //     diff2htmlUi.draw();
  //     diff2htmlUi.highlightCode();
  // }


  if($('#dynamic-chart').length > 0){
    getDataForSunburst()
      .then(function(response){
        var newData = compileDataForSunburst(response)
        var ancestory_string = getAncestoryString(response)

        const color = d3.scaleOrdinal(d3.schemePaired)
        
        // var newCurrentNode = current_roots[0]
        
        const myChart = 
            Sunburst()
              .data(newData)
              .width($('#dynamic-chart').parent().width())
              // .height($('#chart').parent().height())
              .color(d => color(d.name))
              .focusOnNode(eval("newData" + ancestory_string))
              // .focusOnNode(newData.children[2])
              .onNodeClick(node => {
                // console.log(node.descendants())
                // console.log(node.children)
                myChart.focusOnNode(node);
                if(node != null){
                  // console.log(node.name)
                  // console.log(node.id)
                  $.ajax({
                    url: "/skills_with_children/" + `${node.id}`,
                    type: "get",
                    success: function(data) {
          
                      var heading = document.getElementById("node-title")
                      heading.innerHTML = data.node.name.toUpperCase().replace("_", " ")
                      if(document.getElementById('node-list').getElementsByTagName('li').length >= 1){
                        $('#node-list').empty()
                      }
                      if(data.activities.length !=0 ){
                        data.activities.forEach(function(obj){
                          $("#node-list").append("<li id=" + `${obj.id} > -> ` +
                          `<a href="${data.node.id}/activities/${obj.id}/edit?activity_type=${obj.activity_type}" >`
                              + `${obj.activity_type.toUpperCase().replace("_", " ")}` +
                          "</a>"
                            // + `</li>`
                          )
                          // if(!obj.is_premium){
                          //   $("#node-list").append("<li id=" + `${obj.id} > -> ` +
                          //         `<a href="${data.node.id}/activities/${obj.id}/edit?activity_type=${obj.activity_type}" >`
                          //             + `${obj.activity_type.toUpperCase().replace("_", " ")}` +
                          //         "</a>"
                          //     // + `</li>`
                          //   )
                          // }
                          // else{
                          //   if(data.current_profile.is_premium){
                          //     $("#node-list").append("<li id=" + `${obj.id} > ->` +
                          //         `<a href="${data.node.id}/activities/${obj.id}/edit?activity_type=${obj.activity_type}" >`
                          //             + `${obj.activity_type.toUpperCase().replace("_", " ")}` +
                          //         "</a>"
                          //     // + `</li>`
                          //     )
                          //   }
                          //   else{
                          //     $("#node-list").append("<li id=" + `${obj.id} > ->` +
                          //         "<p>Premium Activity</p>"
                          //     // + `</li>`
                          //     )
                          //   }  
                          //   // console.log(data.current_profile.is_premium)
                          // }  
                        })
                      }
                      // if(data.node.is_customizable_with_activity){
                      //   $('#global-view').append(
                      //     `<a href="${data.node.id}/activities/new">Add Customizable activity</a>`
                      //   )
                      // }
                    },
                    error: function(data) { console.log("error") }
                  })
                }
              })
        myChart(document.getElementById('dynamic-chart'))

        updateDynamicChart(myChart)
      })
  }

  if($('#small-chart').length > 0){

    var profile_id = $('#make-chart').attr("profile_id")
    var current_node_id = $('#make-chart').attr("current_node_id")
    $.ajax({
      url: '/get_user_nodes/' + profile_id + '/' + current_node_id,
      type: "get",
      success: function(data){
        var nodeData = data.nodedata
        var current_node = data.current_node
        var actual_node = data.node
        var ancestory_string = data.ancestory_string
        // console.log(current_node)
        nodeData.forEach(obj => renameKey(obj, "sort_order", "value"))
        current_node.forEach(obj => renameKey(obj, "sort_order", "value"))


        var roots = [];
        var all = {};
        var ids = [];

        var current_roots = [];
        var current_all = {};
        var current_ids = [];

        nodeData.forEach(function (node) {
          var nodeId = node.id;
          var convertedNode = convertItem(node);
          all[nodeId] = convertedNode;
          ids.push(nodeId);
        });


        current_node.forEach(function (node) {
          var nodeId = node.id;
          var convertedNode = convertItem(node);
          current_all[nodeId] = convertedNode;
          current_ids.push(nodeId);
        });

        // We use ids array instead of object to maintain its previous order.
        for (let i = 0; i < ids.length; i++) {
          var id = ids[i];
          var convertedNode = all[id];
          var parentId = convertedNode.parent_id;

          if (parentId === null) {
            delete convertedNode.parent_id;
            delete convertedNode.value;
            roots.push(convertedNode);
          } else if (parentId in all) {
            var p = all[parentId];
            if (!('children' in p)) {
              delete p.value
              p.children = []
            }
            delete convertedNode.parent_id;
            p.children.push(convertedNode)
          }
        };

        for (let i = 0; i < current_ids.length; i++) {
          var id = current_ids[i];
          var convertedNode = current_all[id];
          var parentId = convertedNode.parent_id;

          if (parentId === actual_node.id) {
            delete convertedNode.parent_id;
            delete convertedNode.value;
            current_roots.push(convertedNode);
          } else if (parentId in current_all) {
            var p = current_all[parentId];
            if (!('children' in p)) {
              delete p.value
              p.children = []
            }
            delete convertedNode.parent_id;
            p.children.push(convertedNode)
          }
        };





        var newData = roots[0];
        const color = d3.scaleOrdinal(d3.schemePaired)
        
        // var newCurrentNode = current_roots[0]
        
        const myChart = Sunburst();

        myChart
            .data(newData)
            .width($('#small-chart').parent().width())
            .height(300)
            // .height($('#chart').parent().height())
            .color(d => color(d.name))
            .focusOnNode(eval("newData" + ancestory_string))
            // .focusOnNode(newData.children[2])
            .onNodeClick(node => {
              // console.log(node.descendants())
              // console.log(node.children)
              myChart.focusOnNode(node);
            })
            ("#small-chart")
      }
    })
  }

  if($('#chart').length > 0){

    var profile_id = $('#make-chart').attr("profile_id")
    var current_node_id = $('#make-chart').attr("current_node_id")
    $.ajax({
      url: '/get_user_nodes/' + profile_id + '/' + current_node_id,
      type: "get",
      success: function(data){
        var nodeData = data.nodedata
        var current_node = data.current_node
        var actual_node = data.node
        var ancestory_string = data.ancestory_string
        // console.log(current_node)
        nodeData.forEach(obj => renameKey(obj, "sort_order", "value"))
        current_node.forEach(obj => renameKey(obj, "sort_order", "value"))


        var roots = [];
        var all = {};
        var ids = [];

        var current_roots = [];
        var current_all = {};
        var current_ids = [];

        nodeData.forEach(function (node) {
          var nodeId = node.id;
          var convertedNode = convertItem(node);
          all[nodeId] = convertedNode;
          ids.push(nodeId);
        });


        current_node.forEach(function (node) {
          var nodeId = node.id;
          var convertedNode = convertItem(node);
          current_all[nodeId] = convertedNode;
          current_ids.push(nodeId);
        });

        // We use ids array instead of object to maintain its previous order.
        for (let i = 0; i < ids.length; i++) {
          var id = ids[i];
          var convertedNode = all[id];
          var parentId = convertedNode.parent_id;

          if (parentId === null) {
            delete convertedNode.parent_id;
            delete convertedNode.value;
            roots.push(convertedNode);
          } else if (parentId in all) {
            var p = all[parentId];
            if (!('children' in p)) {
              delete p.value
              p.children = []
            }
            delete convertedNode.parent_id;
            p.children.push(convertedNode)
          }
        };

        for (let i = 0; i < current_ids.length; i++) {
          var id = current_ids[i];
          var convertedNode = current_all[id];
          var parentId = convertedNode.parent_id;

          if (parentId === actual_node.id) {
            delete convertedNode.parent_id;
            delete convertedNode.value;
            current_roots.push(convertedNode);
          } else if (parentId in current_all) {
            var p = current_all[parentId];
            if (!('children' in p)) {
              delete p.value
              p.children = []
            }
            delete convertedNode.parent_id;
            p.children.push(convertedNode)
          }
        };





        var newData = roots[0];
        const color = d3.scaleOrdinal(d3.schemePaired)
        
        // var newCurrentNode = current_roots[0]
        
        const myChart = Sunburst();

        myChart
            .data(newData)
            .width($('#chart').parent().width())
            .showTooltip(() => true)
            // .height($('#chart').parent().height())
            .color(d => color(d.name))
            .focusOnNode(eval("newData" + ancestory_string))
            // .focusOnNode(newData.children[2])
            .onNodeClick(node => {
              // console.log(node.descendants())
              // console.log(node.children)
              myChart.focusOnNode(node);
              if(node != null){
                // console.log(node.name)
                // console.log(node.id)
                $.ajax({
                  url: "/skills_with_children/" + `${node.id}`,
                  type: "get",
                  success: function(data) {
        
                    var heading = document.getElementById("node-title")
                    heading.innerHTML = data.node.name.toUpperCase().replace("_", " ")
                    if(document.getElementById('node-list').getElementsByTagName('li').length >= 1){
                      $('#node-list').empty()
                    }
                    if(data.activities.length !=0 ){
                      data.activities.forEach(function(obj){
                        $("#node-list").append("<li id=" + `${obj.id} > ->` +
                              `<a href="${data.node.id}/activities/${obj.id}/edit?activity_type=${obj.activity_type}" >`
                                  + `${obj.activity_type.toUpperCase().replace("_", " ")}` +
                              "</a>"
                          // + `</li>`
                        )
                        // if(!obj.is_premium){
                        //   $("#node-list").append("<li id=" + `${obj.id} > ->` +
                        //         `<a href="${data.node.id}/activities/${obj.id}/edit?activity_type=${obj.activity_type}" >`
                        //             + `${obj.activity_type.toUpperCase().replace("_", " ")}` +
                        //         "</a>"
                        //     // + `</li>`
                        //   )
                        // }
                        // else{
                        //   if(data.current_profile.is_premium){
                        //     $("#node-list").append("<li id=" + `${obj.id} > ->` +
                        //         `<a href="${data.node.id}/activities/${obj.id}/edit?activity_type=${obj.activity_type}" >`
                        //             + `${obj.activity_type.toUpperCase().replace("_", " ")}` +
                        //         "</a>"
                        //     // + `</li>`
                        //     )
                        //   }
                        //   else{
                        //     $("#node-list").append("<li id=" + `${obj.id} > ->` +
                        //         "<p>Premium Activity</p>"
                        //     // + `</li>`
                        //     )
                        //   }  
                        //   // console.log(data.current_profile.is_premium)
                        // }  
                      })
                    }
                    // if(data.node.is_customizable_with_activity){
                    //   $('#global-view').append(
                    //     `<a href="${data.node.id}/activities/new">Add Customizable activity</a>`
                    //   )
                    // }
                    $("#node-list").append("<li id=" + `${data.node.id} > -> ` +
                      `<a href="${data.node.slug}" data-confirm="Are you sure?" data-method="delete">`
                            + `Delete Node` +
                      "</a>"
                      + `</li>`
                    )
                  },
                  error: function(data) { console.log("error") }
                })
              }
            })
            ("#chart")
      }
    })
  }


  $('#behaviourShowChange').bind('change', function(){
    window.location.pathname = $(this).val()
  })

  if($(".tags-input").length > 0){
    $(".tags-input").each(function(){
      $(this).select2({
        tags: true,
        tokenSeparators: [',', ' ']
      });
    })
  }

  if($(".tags-input-single-no-ajax").length > 0){
    $(".tags-input-single-no-ajax").each(function(){
      $(this).select2({
        tags: true,
        width: "100%"
      });
    })
  }

  if($(".tags-input-single").length){
    var nodeId = $(".tags-input-single").attr("data-node")
    var activityId = $(".tags-input-single").attr("data-match-activity")
    var baseType =  $(".tags-input-single").attr("data-required-base-type")
    console.log(baseType)
    if(baseType === "people"){
      var minimumEntry = 3
    }else{
      var minimumEntry = 0
    }
    $(".tags-input-single").select2({
      tags: true,
      minimumInputLength: minimumEntry,
      ajax: {
        url: `/match_skill/${nodeId}/${activityId}`,
        data: function (params) {
          return { search: params.term }
        },
        processResults: function (data, params) {
          return {
            results: $.map(data, function(value, index){
              return {id: value.name, text: value.name};
            })
          };
          cache: true
        }  
      }
    });
  }

  $(function () {
    // $('[data-toggle="tooltip"]').tooltip()
  })

  // if($('#sidebar').count != 0){
  //   var sidebar = new StickySidebar('#sidebar', {
  //     containerSelector: '#content-container',
  //     innerWrapperSelector: '.sidebar__inner',
  //     topSpacing: 20,
  //     bottomSpacing: 20
  //   });
  // }
  
  if($('.crumina-sticky-sidebar').count){
    if($(window).width()<=700){
      $('.crumina-sticky-sidebar').remove()
      $('#sidebar').append('<center><br/><br/><img src="/assets/aktelo/profile.png"></center>')
    }
    else{
      var sidebar = new StickySidebar('.crumina-sticky-sidebar', {
        containerSelector: '#sidebar',
        innerWrapperSelector: '.sidebar__inner',
        topSpacing: 100,
        bottomSpacing: 20
      });
    }
  }

  if($('#chart-top-activity').count != 0){
    if($(window).width()<=700){
      $('#chart-top-activity').css({"margin-top": "10px"})
    }
  }

  if($('#tabs-text-2-tab').count != 0){
    // console.log("test1")
    if($(window).width()>700){
      // console.log("test2")
      $('#books').remove()
      // $('#my')
    }
  }

  if($('#tabs-text-1-tab').count != 0){
    // console.log("test1")
    if($(window).width()>700){
      // console.log("test2")
      $('#tabs-text-1-tab').remove()
      // $('#my')
    }
  }

  $("#addNewNode").click(function(){
    var node_type = $('#node-reference').attr("node_type")
    $("#extra-fields").append(
      // '<label for="node_name">Name</label>' +
      // '<input name="node[][name]" type="text" id="node_name">' +
      // '<input type="hidden" name="node[][content]" id="node_content_trix_input_node">' + 
      // $('#node_content_trix_input_node').html() + 
      // $('.trix-content').html()+ 
      // $('#node_content').html()
      $('.form-class').first().clone().removeAttr('class').addClass(`form-class field-${$("#extra-fields").children().length + 2}`)
      ).find(".trix-content").val("")

    var new_addition = $("#extra-fields").children().length + 1
    // console.log($(`.field-${new_addition}`).find(".append-x"))
    $(`.field-${new_addition}`).find(".display-name").remove()
    $(`.field-${new_addition}`).find("input").val("")
    $(`.field-${new_addition}`).find(".close-button").removeAttr("id").attr("id", `field-${new_addition}`)
    if($(`.field-${new_addition}`).children().find('.tags-input-single').length > 0){
      $(`.field-${new_addition}`).removeClass('select-0')
      $(`.field-${new_addition}`).children().find('.tags-input-single').first().removeClass("select2-hidden-accessible").attr("id", `select-${new_addition}`).attr("data-select2-id", `select-${new_addition}`)
      $(`.field-${new_addition}`).children().find('.select2').remove()
      $(`.field-${new_addition}`).children().find("select").val("")
      console.log($(`.field-${new_addition}`).find(".link-field"))
      var placeholder = $(`.field-${new_addition}`).find(".link-field").attr('placeholder').split(" ")
      placeholder[placeholder.length - 1] = $('.extra-fields').children().length;
      $(`.field-${new_addition}`).find(".link-field").attr('placeholder', placeholder.join(" "))
      var nodeId = $(`.field-${new_addition}`).children().find('.tags-input-single').attr("data-node")
      var activityId = $(`.field-${new_addition}`).children().find('.tags-input-single').attr("data-match-activity")
      var baseType =  $(`.field-${new_addition}`).children().find('.tags-input-single').attr("data-required-base-type")
      console.log(baseType)
      $(`.field-${new_addition}`).children().find('.tags-input-single').select2({
        tags: true,
        minimumInputLength: minimumEntry,
        ajax: {
          url: `/match_skill/${nodeId}/${activityId}`,
          data: function (params) {
            return { search: params.term }
          },
          processResults: function (data, params) {
            return {
              results: $.map(data, function(value, index){
                return {id: value.name, text: value.name};
              })
            };
            cache: true
          }  
        }
      })
    }
  })

  $(".removeButton").click(function(e){
    // $("#extra-fields").append(
    //   // '<label for="node_name">Name</label>' +
    //   // '<input name="node[][name]" type="text" id="node_name">' +
    //   // '<input type="hidden" name="node[][content]" id="node_content_trix_input_node">' + 
    //   // $('#node_content_trix_input_node').html() + 
    //   // $('.trix-content').html()+ 
    //   // $('#node_content').html()

    //   // $('.field').clone().removeClass('field').addClass(`field-${$("#extra-fields").children().length}`).append(`<br/><button type=button id=field-${$("#extra-fields").children().length} onClick=removeFunction();>Remove</button>`)
    // )
    $(this).parent().remove()
  })

  $("#test").click(function(){
  })

  // $('[data-toggle="tooltip"]').tooltip()

  const startConfetti = () => {
    setTimeout(function() {
      confetti.start()
    }, 1000); // 1000 is time that after 1 second start the confetti ( 1000 = 1 sec)
  };

  //  Stop

  const stopConfetti = () => {
    setTimeout(function() {
      confetti.stop()
    }, 5000); // 5000 is time that after 5 second stop the confetti ( 5000 = 5 sec)
  };

  const myDiv = document.getElementById('completed');
  if (myDiv) {

    // var notyf = new Notyf();
    // notyf.success({
    //   message: myDiv.innerText,
    //   duration: 8000,
    //   dismissible: true,
    //   ripple: true,
    //   position: {
    //     x: 'center',
    //     y: 'center',
    //   },
    // });

    // Trigger your event here
    startConfetti();
    stopConfetti();

  }

})


window.removeFunction = () => {
  const e = window.event
  if($('.extra-fields').children().length > 1){
    console.log(`.${e.target.id}`)
    $(`.${e.target.id}`).remove()
  }
  else{
    $(`.${e.target.id}`).find("input").val("")
    $(`.${e.target.id}`).find(".trix-content").val("")
    $(`.${e.target.id}`).find(".tags-input-single").val("").trigger('change');
    var placeholder = $(`.${e.target.id}`).find(".link-field").attr('placeholder').split(" ")
    placeholder[placeholder.length - 1] = $('.extra-fields').children().length;
    $(`.${e.target.id}`).find(".link-field").attr('placeholder', placeholder.join(" ")) }
}

window.clearFirstInput = () => {
  const e = window.event
  $(`.${e.target.id}`).find("select2").select2("val", "");
  $(`.${e.target.id}`).find("input").val("")
}

function get_activities(id){
  return $.ajax({
    url: "/get_activities/" + `${id}`
  })
}


function convertItem(item) {
  let newItem = {};
  newItem = item;
  return newItem;
}

function nameOnly(node){
  let nameNode = {};
  nameNode.name = node.name.name
  return nameNode
}

function renameKey ( obj, oldKey, newKey ) {
  obj[newKey] = obj[oldKey];
  delete obj[oldKey];
}

function updateNotficationIcon(){
  $("#remove-note").remove()
  // $('#notification-append').append(
  //   '<svg class="olymp-thunder-icon"><use xlink:href="/assets/svg-icons/sprites/icons-e342751a72d4b06b6bb33ef3c513de00dccb0bf1627840f5d342096375593ac7.svg#olymp-thunder-icon"></svg>'
  //   )
}

function compileDataForSunburst(data){
  var nodeData = data.nodedata
  var current_node = data.current_node
  var actual_node = data.node
  nodeData.forEach(obj => renameKey(obj, "sort_order", "value"))
  current_node.forEach(obj => renameKey(obj, "sort_order", "value"))


  var roots = [];
  var all = {};
  var ids = [];

  var current_roots = [];
  var current_all = {};
  var current_ids = [];

  nodeData.forEach(function (node) {
    var nodeId = node.id;
    var convertedNode = convertItem(node);
    all[nodeId] = convertedNode;
    ids.push(nodeId);
  });


  current_node.forEach(function (node) {
          var nodeId = node.id;
          var convertedNode = convertItem(node);
          current_all[nodeId] = convertedNode;
          current_ids.push(nodeId);
  });

        // We use ids array instead of object to maintain its previous order.
    for (let i = 0; i < ids.length; i++) {
          var id = ids[i];
          var convertedNode = all[id];
          var parentId = convertedNode.parent_id;

          if (parentId === null) {
            delete convertedNode.parent_id;
            delete convertedNode.value;
            roots.push(convertedNode);
          } else if (parentId in all) {
            var p = all[parentId];
            if (!('children' in p)) {
              delete p.value
              p.children = []
            }
            delete convertedNode.parent_id;
            p.children.push(convertedNode)
          }
      };

    for (let i = 0; i < current_ids.length; i++) {
          var id = current_ids[i];
          var convertedNode = current_all[id];
          var parentId = convertedNode.parent_id;

          if (parentId === actual_node.id) {
            delete convertedNode.parent_id;
            delete convertedNode.value;
            current_roots.push(convertedNode);
          } else if (parentId in current_all) {
            var p = current_all[parentId];
            if (!('children' in p)) {
              delete p.value
              p.children = []
            }
            delete convertedNode.parent_id;
            p.children.push(convertedNode)
          }
    };

    return roots[0];
}

function getDataForSunburst(){
  var profile_id = $('#make-chart').attr("profile_id")
  var current_node_id = $('#make-chart').attr("current_node_id")

  var response_data = 
  $.ajax({
    url: '/get_user_nodes/' + profile_id + '/' + current_node_id,
    type: "get",
    // async: false,
    success: function(data){
      console.log(data)
      // return data
    }
  })

  return response_data

}

function getAncestoryString(data){
  return data.ancestory_string
}

function updateDynamicChart(chart){
  console.log("Dyanamic updating chart")
  getDataForSunburst()
  .then(function(response){
    var newData = compileDataForSunburst(response)
    // console.log(newData)
    var ancestory_string = getAncestoryString(response)
    // console.log(ancestory_string)

    const color = d3.scaleOrdinal(d3.schemePaired)

    chart.data(newData)
    document.getElementById('dynamic-chart').innerHTML=''
    chart(document.getElementById('dynamic-chart'));
    var nextDate = new Date();
    nextDate.setHours(nextDate.getHours() + 1);
    nextDate.setMinutes(0);
    nextDate.setSeconds(0);
    var difference = nextDate - new Date();
    setTimeout(() => {updateDynamicChart(chart)}, 100000)
  })
}

function expandToActiveNode(node) {
  if (node) {
      node.setExpanded(true);
      expandToActiveNode(node.getParent());
  }
}


// document.addEventListener('turbo:load', () => {
//   // const clickButton = document.getElementById("button-click");
//   //clickButton.addEventListener("click", dosmth);
//   // alert('hello');

// });
